import * as tslib_1 from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { accountRules } from '@/common/js/form-rule/index';
import { URLS } from '@/common/js/constant/index';
import Cookie from 'js-cookie';
let Settings = class Settings extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            old_password: '',
            new_password: '',
            confirm_new_password: '',
        };
        this.formRules = {};
        this.isLoading = false;
        this.isNotSetPassword = false;
    }
    created() {
        this.formRules = accountRules.call(null, this.form);
        this.getPasswordLogList();
    }
    getPasswordLogList() {
        this.$ajax.get(URLS.getPasswordUpdateLog)
            .then((res) => {
            this.isNotSetPassword = !res.change_log || res.change_log.length === 0;
        })
            .catch((err) => {
            console.log(`Error: ${err}`);
        });
    }
    save() {
        const form = this.$refs.form;
        form.validate((valid) => {
            if (valid) {
                this.isLoading = true;
                this.$ajax.post(URLS.changePassword, {
                    new_password: KLOOK.md5(this.form.new_password),
                    old_password: this.isNotSetPassword ? null : KLOOK.md5(this.form.old_password),
                }).then(() => {
                    this.$successTips();
                    this.$store.commit('user/cleanUserInfo');
                    Cookie.remove('isLogin');
                    this.$router.push({ name: 'login' });
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        });
    }
};
Settings = tslib_1.__decorate([
    Component
], Settings);
export default Settings;
